import { EventEmitter, Injectable, Output } from '@angular/core';
import { ApiconfigService } from './apiconfig.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  @Output() profileDataEvent: EventEmitter<string> = new EventEmitter();
  private urls;
  private baseurl;
  private baseurl2;
  private baseurl3;

  constructor(private http: HttpClient, private apiCS: ApiconfigService) {
    this.urls = this.apiCS.getUrls();
    this.baseurl = this.apiCS.baseUrl;
    this.baseurl2 = this.apiCS.baseUrl2;
    this.baseurl3 = this.apiCS.baseUrl3;
  }

  get(url, callback, error): void;
  get(url, callback, error, options): any;
  get(url, callback, error, options?: any): any {
    let headers = {};
    let path = this.getUrl(url);
    if (options !== undefined) {
      if (options.params === undefined) {
        headers = options;
      } else {
        options.params.forEach((each) => {
          path += '/' + each;
        });
      }
    }
    console.log(headers);
    this.http.get(path, headers).toPromise().then(callback).catch(error);
  }

  post(url, body, callback, error, options): any {
    let headers = {};
    if (options !== undefined) {
      headers = options;
    }
    this.http
      .post(this.getUrl(url), body, headers)
      .toPromise()
      .then(callback)
      .catch(error);
  }

  async asyncPost(url, body, options) {
    let headers = {};
    if (options !== undefined) {
      headers = options;
    }
    const res = await this.http
      .post(this.getUrl(url), body, headers)
      .toPromise();
    return res;
  }

  postAuth(url, body, callback, error, options): any {
    let headers = {};
    if (options !== undefined) {
      headers = options;
    }
    this.http
      .post(this.getUrl2(url), body, headers)
      .toPromise()
      .then(callback)
      .catch(error);
  }

  postCms(url, body, callback, error, options): any {
    let headers = {};
    if (options !== undefined) {
      headers = options;
    }
    this.http
      .post(this.getUrl3(url), body, headers)
      .toPromise()
      .then(callback)
      .catch(error);
  }

  put(url, body, callback, error): any {
    this.http
      .put(this.getUrl(url), body)
      .toPromise()
      .then(callback)
      .catch(error);
  }

  putAuth(url, body, callback, error): any {
    this.http
      .put(this.getUrl2(url), body)
      .toPromise()
      .then(callback)
      .catch(error);
  }

  putCms(url, body, callback, error): any {
    this.http
      .put(this.getUrl3(url), body)
      .toPromise()
      .then(callback)
      .catch(error);
  }

  delete(url, body, callback, error): any {
    this.http
      .delete(this.getUrl(url), body)
      .toPromise()
      .then(callback)
      .catch(error);
  }

  deleteAuth(url, body, callback, error): any {
    this.http
      .delete(this.getUrl2(url), body)
      .toPromise()
      .then(callback)
      .catch(error);
  }

  deleteCms(url, body, callback, error): any {
    this.http
      .delete(this.getUrl3(url), body)
      .toPromise()
      .then(callback)
      .catch(error);
  }

  getUrl(name): any {
    return this.baseurl + this.urls[name];
  }
  getUrl2(name): any {
    return this.baseurl2 + this.urls[name];
  }
  getUrl3(name): any {
    return this.baseurl3 + this.urls[name];
  }
  getPolicyData(body) {
    return this.http.post(this.baseurl + 'getqualifiers', body);
  }
  getPolicyResourceData(body) {
    return this.http.post(this.baseurl + 'getqualifiervalues', body);
  }
  accountProfile(body): any {
    return this.http.post(this.baseurl + 'tenant_profile', body);
  }
  profileData(data) {
    console.log('serviceProfile:', data);
    this.profileDataEvent.emit(data);
  }
}
