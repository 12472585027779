import { Component, Input } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import  { IMenuItem , cartProductData} from 'src/app/constants/menu';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/constants/common.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})

export class BreadcrumbComponent {
  @Input() title = '';
  // menuItems: cartProductData = new cartProductData() ;
  IdmRole: any;
  menuItems = [];
  path = '';
  pathArr: string[] = [];
    newPaths = [{path: '/catalog/categories/add', label: 'Add Category'},
                {path: '/catalog/products/addProduct', label: 'Add Product'},
                {path: '/catalog/recurring-profiles/recurringprofiles', label: 'Add RecurringProfile'},
                {path: '/catalog/filters/addfilter', label: 'Add Filter'},
                {path: '/catalog/attributes/add-attribute', label: 'Add Attribute'},
                {path: '/catalog/attribute-groups/add-attributegroup', label: 'Add AttributeGroup'},
                {path: '/catalog/options/addoption', label: 'Add Option'},
                {path: '/catalog/manufacturers/add-manufacturer', label: 'Add Manufacturers'},
                {path: '/catalog/downloads/addDownload', label: 'Add Downloads'},
                {path: '/catalog/reviews/addReview', label: 'Add Review'},
                {path: '/catalog/information/addinformation', label: 'Add Information'},
                {path: '/sales/orders/addorder', label: 'Add Order'},
                {path: '/sales/orders/addreturn', label: 'Add Return'},
                {path: '/sales/orders/addvoucher', label: 'Add Voucher'},
                {path: '/sales/orders/addvouchertheme', label: 'Add VoucherTheme'},
                {path: '/customers/customers/addcustomer', label: 'Add Customer'},
                {path: '/customers/customers/addcustomergroup', label: 'Add CustomerGroup'},
                {path: '/customers/customers/addcustomfield', label: 'Add CustomField'},
                {path: '/sales/orders/ordersview', label: 'Orders View'},
                {path: '/vendors/vendors/addvendors', label: 'Add vendors'},
                {path: '/delivery/delivery-executive/addexecutive', label: 'Add-Executive'},
                 {path: '/vendors/addVendorProduct', label: 'Add Product'}

              ]
  constructor(private router: Router, private activatedRoute: ActivatedRoute,  public common: CommonService,
    public http: HttpClient) {
    this.IdmRole = localStorage.getItem('idmRole')

      this.getMenuList();
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      })
    ).subscribe((event) => {
      this.path = this.router.url.slice(1, this.router.url.split('?')[0].length);
      const paramtersLen = Object.keys(event.snapshot.params).length;
      this.pathArr = this.path.split('/').slice(0, this.path.split('/').length - paramtersLen);
        // //(this.pathArr)
    });
  }
  getMenuList() {
    const success = this.onSuccessMenu.bind(this);
    const error = this.onError.bind(this);
    const body = {
      "idm_roles": [  this.IdmRole ],
      "appl_name": "Ecom"
    };
    this.common.http.postAuth('auth', body, success, error);
  }

  onSuccessMenu(data) {
    if (data.res_status == true) {
      // //(data)
      // //( 'before map',data.result.auth_objects)
      this.menuItems =  data.result.auth_objects;
      // data.result.auth_objects.map((element)=>{
      // //( 'befafter map',data.result.auth_objects)

      // // element.children.map((item)=>{
      // //   const childDataSet =  new IMenuItem();
      // //   childDataSet.to = item.link,
      // //   childDataSet.label = item.name
      // //   childDataSet.icon = 'simple-icon-check'
      // //   childDataSet.id = item.appID
      // //   childDataSet.subs = item.children
      // //   this.childrenArray.subs.push(childDataSet)
      // // //( 'chilfren menu map',this.childrenArray.subs)
      // // })

      // const dataset = new IMenuItem();
      // //( 'IN  map  ',dataset)
      //   dataset.label = element.name;
      //   // dataset.subs =  this.childrenArray.subs;
      //   dataset.subs =   element.children;
      //   dataset.icon = 'iconsminds-user';
      //   dataset.to = element.link 
      //   this.menuItems.data.push(dataset) 
      // //( 'parent menu  map  ',this.menuItems.data)

      // })
      // this.authObjects = data.result.auth_objects;
      // //('menu items response ', this.menuItems)
      const obj = data.result.roleid;
      localStorage.setItem('roleId', obj);
      sessionStorage.setItem('timeline_auth', JSON.stringify(data));
    } else {
    }
  }
  onError(error) {
  }
  getUrl = (sub: string) => {
    // //(sub)
    return '/' + this.path.split(sub)[0] + sub;
  }

  getLabel(path): string {
    // //(path)
    // if (path === environment.adminRoot) {
    //   return 'menu.home';
    // }

    // step 0
    // //(this.menuItems);
    const excludeList = [];
    this.menuItems.map(ele => {
      excludeList.push(ele.link);
    });
    let foundedMenuItem = this.menuItems.find(x => x.link === path);

    if (!foundedMenuItem) {
      // step 1
      this.menuItems.map(menu => {
        if (!foundedMenuItem && menu.children) {foundedMenuItem = menu.children.find(x => x.link === path); }
      });
      if (!foundedMenuItem) {
        // step 2
        this.menuItems.map(menu => {
          if (menu.children) {
            menu.children.map(sub => {
                if (!foundedMenuItem && sub.children) {foundedMenuItem = sub.children.find(x => x.link === path); }
              });
          }
        });
        if (!foundedMenuItem) {
          // step 3
          this.menuItems.map(menu => {
            if (menu.children) {
              menu.children.map(sub => {
                if (sub.children) {
                  sub.children.map(deepSub => {
                    if (!foundedMenuItem && deepSub.children) {foundedMenuItem = deepSub.children.find(x => x.link === path); }
                  });
                }
              });
            }
          });
        }
      }
    }

    for(let i=0; i<this.newPaths.length; i++){
      if(path == this.newPaths[i]['path']){
        return this.newPaths[i]['label']
      }
    }
    if (foundedMenuItem) {
      if (excludeList.includes(foundedMenuItem.link)){
        return '';
      } else {
        return foundedMenuItem.name;

      }
    } else { return ''; }
  }

}
