<div class="menu" (click)="menuClicked($event)">
    <div class="main-menu" style="height: 100% !important;  width: 6%;">
        <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
            <ul class="list-unstyled">
                <li *ngFor="let item of menuItems.data"
                    [ngClass]="{'active': (selectedParentMenu === item.to && viewingParentMenu === '') || viewingParentMenu === item.to}">
                    <a *ngIf="item.newWindow" [href]="item.to" rel="noopener noreferrer" target="_blank">
                        <i [ngClass]="item.icon"></i>{{item.label | translate}}</a>
                    <a *ngIf="!item.newWindow && item.subs &&  item.subs.length>0"
                        (click)="openSubMenu($event,item); false" [href]="item.to">
                        <i [ngClass]="item.icon"></i> {{item.label | translate}}</a>
                    <a *ngIf="!item.newWindow && (!item.subs || (item.subs &&  item.subs.length===0))"
                        (click)="changeSelectedParentHasNoSubmenu(item.to); false" [routerLink]="item.to">
                        <i [ngClass]="item.icon"></i> {{item.label | translate}}</a>

                </li>
            </ul>
        </perfect-scrollbar>
    </div>
    <div class="sub-menu" style="height: 100% !important; width: 13%;">
        <perfect-scrollbar class="scroll" [config]="{suppressScrollX: true}">
            <ul class="list-unstyled" *ngFor="let item of menuItems.data" [attr.data-parent]="item.to"
                [ngClass]="{'d-block': (selectedParentMenu === item.to && viewingParentMenu==='') || viewingParentMenu === item.to}">
                <!-- subs li---{{item.subs | json}} -->
               
                <li *ngFor="let sub of item.subs"
                    [ngClass]="{'active': currentUrl === sub.link ,'has-sub-item' : sub.children&& sub.children.length>0 }">
                    <a *ngIf="sub.newWindow" [href]="sub.link" rel="noopener noreferrer" target="_blank">
                        <i [ngClass]="sub.icon"></i>
                        <span class="d-inline-block">{{sub.name | translate}}</span>
                    </a>
                    <a *ngIf="!sub.newWindow && (!sub.children || sub.children.length<=0)" [routerLink]="[sub.link]"
                        routerLinkActive="active">
                        <i [ngClass]="sub.icon"></i>
                        <span class="d-inline-block">{{sub.name | translate}}</span>
                    </a>
                    <a (click)="toggleCollapse(sub.link)" *ngIf="sub.children && sub.children.length > 0"
                      [ngClass]="{'rotate-arrow-icon opacity-50 c-pointer': true, 'collapsed': closedCollapseList.includes(sub.to)}">
                        <i class="simple-icon-arrow-down"></i>
                        <span class="d-inline-block">{{sub.name | translate}}</span>
                    </a>
                    <div [collapse]="closedCollapseList.includes(sub.link)"  [isAnimated]="true">
                        <div *ngIf="sub.children && sub.children.length > 0">
                            <ul class="list-unstyled inner-level-menu">
                                <!-- deepSub li---{{sub.children | json}} -->
                                <li *ngFor="let deepSub of sub.children" [ngClass]="{'active': currentUrl === deepSub.link}">
                                    <a *ngIf="deepSub.newWindow" [href]="deepSub.link" rel="noopener noreferrer"
                                        target="_blank">
                                        <i [ngClass]="deepSub.icon"></i>
                                        <span class="d-inline-block">{{deepSub.name | translate}}</span>
                                    </a>
                                    <a *ngIf="!deepSub.newWindow && (!deepSub.children || deepSub.children.length<=0)"
                                        [routerLink]="[deepSub.link]" routerLinkActive="active">
                                        <i [ngClass]="deepSub.icon"></i>
                                        <span class="d-inline-block">{{deepSub.name | translate}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
            </ul>
        </perfect-scrollbar>
    </div>
</div>
