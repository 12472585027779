import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { SidebarService, ISidebar } from './sidebar.service';
import  { IMenuItem , cartProductData} from 'src/app/constants/menu';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/constants/common.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { UserRole } from 'src/app/shared/auth.roles';
// import { AuthService } from 'src/app/shared/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
  menuItems: cartProductData = new cartProductData() ;
  childrenArray : IMenuItem = new IMenuItem();
  selectedParentMenu = '';
  viewingParentMenu = '';
  currentUrl: string; 

  sidebar: ISidebar;
  subscription: Subscription;
  closedCollapseList = [];

  currentUser = null;
  IdmRole: any;

  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    private activatedRoute: ActivatedRoute,
     public common: CommonService,
     public http: HttpClient,
    // private authService: AuthService
  ) {
    this.IdmRole = localStorage.getItem('idmRole')
    // this.authService.getUser().then((user) => {
    //   this.currentUser = user;
    // });
    // ('constructor')
    this.getMenuList();
    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((event) => {
        const path = this.router.url.split('?')[0];
        const paramtersLen = Object.keys(event.snapshot.params).length;
        const pathArr = path
          .split('/')
          .slice(0, path.split('/').length - paramtersLen);
        this.currentUrl = pathArr.join('/');
      });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const { containerClassnames } = this.sidebar;
        // this.selectMenu();
        this.getMenuList();
        this.toggle();
        this.sidebarService.setContainerClassnames(
          0,
          containerClassnames,
          this.sidebar.selectedMenuHasSubItems
        );
        window.scrollTo(0, 0);
      });
  }

  async ngOnInit(): Promise<void> {
    
  

    setTimeout(() => {
      // this.getMenuList();
      // this.selectMenu();
      const { containerClassnames } = this.sidebar;
      const nextClasses = this.getMenuClassesForResize(containerClassnames);
      this.sidebarService.setContainerClassnames(
        0,
        nextClasses.join(' '),
        this.sidebar.selectedMenuHasSubItems
      );
      this.isCurrentMenuHasSubItem();
    }, 100);
  }

  getMenuList() {
    //;
    const success = this.onSuccessMenu.bind(this);
    const error = this.onError.bind(this);
    const body = {
      "idm_roles": [this.IdmRole],
      "appl_name": "Ecom"
    };
    this.common.http.postAuth('auth', body, success, error);
    
  }

  onSuccessMenu(data) {
    this.menuItems.data = Array<IMenuItem>();
    this.childrenArray.subs = Array<IMenuItem>();
    if (data.res_status == true) {
      data.result.auth_objects.map((element)=>{

      // element.children.map((item)=>{
      //   const childDataSet =  new IMenuItem();
      //   childDataSet.to = item.link,
      //   childDataSet.label = item.name
      //   childDataSet.icon = 'simple-icon-check'
      //   childDataSet.id = item.appID
      //   childDataSet.subs = item.children
      //   this.childrenArray.subs.push(childDataSet)
      // ( 'chilfren menu map',this.childrenArray.subs)
      // })

      const dataset = new IMenuItem();
        dataset.label = element.name;
        if( element.name == "Dashboard"){
          dataset.icon = 'iconsminds-shop-4';
        } else  if( element.name == "Catalog"){
          dataset.icon = 'fa fa-tag';
        }else  if( element.name == "Sales"){
          dataset.icon = 'fa fa-line-chart';
        }else  if( element.name == "Customers" || element.name == "Vendors"){
          dataset.icon = 'fa fa-user-o';
        }else  if( element.name == "System"){
          dataset.icon = 'fa fa-cogs';
        }else  if( element.name == "Purchases"){
          dataset.icon = 'fa fa-shopping-bag';
        }else  if( element.name == "Stores"){
          dataset.icon = 'iconsminds-shop-4';
        }else  if( element.name == "Delivery Executive"){
          dataset.icon = 'fa fa-truck';
        }else  if( element.name == "Store Enquiry"){
          dataset.icon = 'fa fa-id-card-o';
        }


        dataset.subs =   element.children;
        // dataset.icon = 'iconsminds-user';
        dataset.to = element.link 
        this.menuItems.data.push(dataset) 

      })
      // this.authObjects = data.result.auth_objects;
      const obj = data.result.roleid;
      localStorage.setItem('roleId', obj);
      sessionStorage.setItem('timeline_auth', JSON.stringify(data));
      this.selectMenu();
    } else {
    }
  }
  onError(error) {
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectMenu(): void {
    this.selectedParentMenu = this.findParentInPath(this.currentUrl) || '';
    this.isCurrentMenuHasSubItem();
  }

  findParentInPath(path): any {
    const foundedMenuItem = this.menuItems.data.find((x) => x.to === path);
    if (!foundedMenuItem) {
      if (path.split('/').length > 1) {
        const pathArr = path.split('/');
        return this.findParentInPath(
          pathArr.slice(0, pathArr.length - 1).join('/')
        );
      } else {
        return undefined;
      }
    } else {
      return path;
    }
  }

  isCurrentMenuHasSubItem(): boolean {
    const { containerClassnames } = this.sidebar;

    const menuItem = this.menuItems.data.find(
      (x) => x.to === this.selectedParentMenu
    );
    const isCurrentMenuHasSubItem =
      menuItem && menuItem.subs && menuItem.subs.length > 0 ? false : true;
    if (isCurrentMenuHasSubItem !== this.sidebar.selectedMenuHasSubItems) {
      if (!isCurrentMenuHasSubItem) {
        this.sidebarService.setContainerClassnames(
          0,
          containerClassnames,
          false
        );
      } else {
        this.sidebarService.setContainerClassnames(
          0,
          containerClassnames,
          true
        );
      }
    }
    return isCurrentMenuHasSubItem;
  }

  changeSelectedParentHasNoSubmenu(parentMenu: string): void {
    const { containerClassnames } = this.sidebar;
    this.selectedParentMenu = parentMenu;
    this.viewingParentMenu = parentMenu;
    this.sidebarService.changeSelectedMenuHasSubItems(false);
    this.sidebarService.setContainerClassnames(0, containerClassnames, false);
  }

  openSubMenu(event: { stopPropagation: () => void }, menuItem: IMenuItem): void {
    if (event) {
      event.stopPropagation();
    }
    const { containerClassnames, menuClickCount } = this.sidebar;

    const selectedParent = menuItem.to;
    const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
    this.sidebarService.changeSelectedMenuHasSubItems(hasSubMenu);
    if (!hasSubMenu) {
      this.viewingParentMenu = selectedParent;
      this.selectedParentMenu = selectedParent;
      this.toggle();
    } else {
      const currentClasses = containerClassnames
        ? containerClassnames.split(' ').filter((x) => x !== '')
        : '';

      if (!currentClasses.includes('menu-mobile')) {
        if (
          currentClasses.includes('menu-sub-hidden') &&
          (menuClickCount === 2 || menuClickCount === 0)
        ) {
          this.sidebarService.setContainerClassnames(
            3,
            containerClassnames,
            hasSubMenu
          );
        } else if (
          currentClasses.includes('menu-hidden') &&
          (menuClickCount === 1 || menuClickCount === 3)
        ) {
          this.sidebarService.setContainerClassnames(
            2,
            containerClassnames,
            hasSubMenu
          );
        } else if (
          currentClasses.includes('menu-default') &&
          !currentClasses.includes('menu-sub-hidden') &&
          (menuClickCount === 1 || menuClickCount === 3)
        ) {
          this.sidebarService.setContainerClassnames(
            0,
            containerClassnames,
            hasSubMenu
          );
        }
      } else {
        this.sidebarService.addContainerClassname(
          'sub-show-temporary',
          containerClassnames
        );
      }
      this.viewingParentMenu = selectedParent;
    }
  }

  toggle(): void {
    const { containerClassnames, menuClickCount } = this.sidebar;
    const currentClasses = containerClassnames
      .split(' ')
      .filter((x) => x !== '');
    if (currentClasses.includes('menu-sub-hidden') && menuClickCount === 3) {
      this.sidebarService.setContainerClassnames(
        2,
        containerClassnames,
        this.sidebar.selectedMenuHasSubItems
      );
    } else if (
      currentClasses.includes('menu-hidden') ||
      currentClasses.includes('menu-mobile')
    ) {
      if (!(menuClickCount === 1 && !this.sidebar.selectedMenuHasSubItems)) {
        this.sidebarService.setContainerClassnames(
          0,
          containerClassnames,
          this.sidebar.selectedMenuHasSubItems
        );
      }
    }
  }

  toggleCollapse(id: string): void {
    if (this.closedCollapseList.includes(id)) {
      this.closedCollapseList = this.closedCollapseList.filter((x) => x !== id);
    } else {
      this.closedCollapseList.push(id);
    }
  }

  getMenuClassesForResize(classes: string): string[] {
    let nextClasses = classes.split(' ').filter((x: string) => x !== '');
    const windowWidth = window.innerWidth;

    if (windowWidth < this.sidebarService.menuHiddenBreakpoint) {
      nextClasses.push('menu-mobile');
    } else if (windowWidth < this.sidebarService.subHiddenBreakpoint) {
      nextClasses = nextClasses.filter((x: string) => x !== 'menu-mobile');
      if (
        nextClasses.includes('menu-default') &&
        !nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses.push('menu-sub-hidden');
      }
    } else {
      nextClasses = nextClasses.filter((x: string) => x !== 'menu-mobile');
      if (
        nextClasses.includes('menu-default') &&
        nextClasses.includes('menu-sub-hidden')
      ) {
        nextClasses = nextClasses.filter(
          (x: string) => x !== 'menu-sub-hidden'
        );
      }
    }
    return nextClasses;
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    this.viewingParentMenu = '';
    this.selectMenu();
    this.toggle();
  }

  @HostListener('window:resize', ['$event'])
  handleWindowResize(event): void {
    if (event && !event.isTrusted) {
      return;
    }
    const { containerClassnames } = this.sidebar;
    const nextClasses = this.getMenuClassesForResize(containerClassnames);
    this.sidebarService.setContainerClassnames(
      0,
      nextClasses.join(' '),
      this.sidebar.selectedMenuHasSubItems
    );
    this.isCurrentMenuHasSubItem();
  }

  menuClicked(e: MouseEvent): void {
    e.stopPropagation();
  }

  // tslint:disable-next-line:no-shadowed-variable
  filteredMenuItems(menuItems: IMenuItem[]): IMenuItem[] {
    return menuItems
      ? menuItems.filter(
          (x) =>
            !x.roles || (x.roles && x.roles.includes(this.currentUser.role))
        )
      : [];
  }
}
