<!-- {{dropList | json}}----top -->

<div class="cards">
    <div class="row">
      <div class="col-12 data-table-rows">
            <p-table [columns]="columns" [value]="rowData" [(selection)]="selectedRows"  [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" 
            [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [scrollable]="true" scrollHeight="400px" >
                <ng-template pTemplate="header" let-columns > 
                    <tr>
                        <!-- <th style="width: 3rem">
                            <p-tableHeaderCheckbox (click)="selectRow($event)"></p-tableHeaderCheckbox>
                        </th> -->
                        <th *ngFor="let col of columns" pSortableColumn="{{col.field }}">
                            <div *ngIf="col.field != 'action' && col.field != 'product_quantity' && col.field != 'slNo'">
                                {{col.header}}
                                <p-sortIcon field="{{col.field }}" class="d-sort"></p-sortIcon>
                            </div>
                            <!-- margin-right: 42px; -->
                            <div *ngIf="col.field === 'action' || col.field === 'product_quantity' || col.field === 'slNo'" >
                                {{col.header}}
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <!-- <td>
                            <p-tableCheckbox [value]="rowData" (click)="selectRow($event)"></p-tableCheckbox>
                        </td> -->
                        <td *ngFor="let col of columns">
                            <div *ngIf="col.field != 'status' && col.field != 'product_quantity' && col.field != 'image' && col.field != 'purchaseorder_status'">
                                {{rowData[col.field]}}
                            </div>
                            <div *ngIf="col.field === 'slNo'">
                                {{getIndex(rowData)+1}}
                            </div>
                            <div *ngIf="col.field === 'product_quantity'" style="text-align: center;">
                                {{rowData.product_quantity}}
                            </div>
                            <div *ngIf="col.field === 'image'">
                                <img [src] ="rowData.imageUrl" width="90" height="50" >
                            </div>
                            <!-- <div *ngIf="col.field === 'purchaseorder_status'">
                            </div> -->
                            <div *ngIf="col.field === 'status'">
                                <span *ngIf=" rowData.status === false">Disabled</span>
                                <span *ngIf=" rowData.status === true">Enabled</span>
                                <!-- <span *ngIf="rowData.purchaseorder_status == 'Pending'" style="color: yellow !important;">{{rowData.status}}</span>
                                <span *ngIf=" rowData.status === 'Approved'">{{rowData.status}}</span>
                                <span *ngIf=" rowData.status === 'Rejected'">{{rowData.status}}</span> -->
                                <!-- <span *ngIf=" rowData.status != true && rowData.status != false">{{rowData.status}} </span> -->
                            </div>
                            <div  *ngIf=" col.field === 'purchaseorder_status' || col.field === 'status'">
                                <span [ngClass]="{'class1':rowData[col.field] === 'Pending','class2':rowData[col.field] === 'Approved','class3':rowData[col.field] === 'Rejected','class4':rowData[col.field] === 'Completed'}">
                                    {{rowData[col.field]}}
                                </span>
                            </div>
                            <!-- margin-right: 30px; -->
                            <div *ngIf="col.field === 'action'" >
                                <!-- <i class="fa fa-eye" style="font-size: 20px; color: #61aed0; cursor: pointer;" (click)="view(rowData)"></i>&nbsp; -->
                                
                                <div class="btn-group" dropdown [autoClose]="true">
                                    <!-- <button type="button" class="btn btn-outline-primary">Primary</button> -->

                                    <button id="button-basic" dropdownToggle type="button" 
                                       class="btn btn-outline-primary dropdown-toggle d-but"
                                       aria-controls="dropdown-basic">
                                       Action <span class="caret"></span>
                                    </button>
                                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu d-ul"
                                       role="menu" aria-labelledby="button-basic">
                                       <li role="menuitem" class="d-drop" *ngFor= "let item of dropList" (click)="view(rowData, item)">{{item}}</li>
                                    </ul>
                                 </div>

                                <!-- <div class="btn-group">
                                    <div class="dropdown">
                                        <button type="button btn-sm" class="btn d-but dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Action
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item"  *ngFor= "let item of dropList" (click)="view(rowData, item)">{{item}}</a>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!-- {{rowData | json}} -->
                            <div *ngIf="rowData.length==0">
                                No Records Found
                            </div>
                        </td>
                    </tr>
                    <!--   {{rowData | json}}   -->
                </ng-template>
            </p-table>
        </div>
    </div>
  </div>






