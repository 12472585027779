<!-- <div class="row">
    <div class="col-12 data-table-rows">
        <ngx-datatable style="text-align: center;" class="material" [rows]="rowDataDetails" 
            [columns]="displayedColumnsDetails" [columnMode]="'force'" [limit]="10" [headerHeight]="20"
             [footerHeight]="100" #table >

            <ngx-datatable-column [width]="30" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                [resizeable]="false" [headerCheckboxable]="true" [checkboxable]="true">
            </ngx-datatable-column>


            <ngx-datatable-column *ngFor="let column of displayedColumns; let i = index;" name="{{column.name}}"
                prop="{{column.prop}}">
            </ngx-datatable-column> -->
<!-- <ngx-datatable-column *ngFor="let column of displayedColumns; let i = index;"   name="{{column.}}" prop="{{column.prop}}">
    </ngx-datatable-column>  (select)="onSelect($event)" [selected]="selected" -->

<!-- <ngx-datatable-column name="Action" [sortable]="false">
                <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <span>
                        <i class="fa fa-pencil-square-o" style="font-size: 21px; color: blue; cursor: pointer;"
                            (click)="editAttribute(row)"></i>&nbsp; &nbsp;
                        <i class="fa fa-trash-o" style="font-size: 21px; color: red; cursor: pointer;"
                            (click)="singleDelete(deleteAll,row)"></i>
                    </span>
                </ng-template>
            </ngx-datatable-column> -->
<!-- <ngx-datatable-footer *ngIf="true">
                <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                    let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-limit="limit">
                    <div class="w-100 d-flex justify-content-center">
                        <pagination [totalItems]="rowCount" [itemsPerPage]="pageSize" [maxSize]="5"
                            (pageChanged)="table.onFooterPage($event)" [customNextTemplate]="nextTemplate"
                            [customPreviousTemplate]="prevTemplate">
                        </pagination>
                    </div>
                </ng-template>
            </ngx-datatable-footer>
        </ngx-datatable>
    </div>
</div> -->




<div class="card ">
    <div class="row">
        <div class="col-12 data-table-rows">
            <!-- {{selectedRows | json}} -->
            <p-table [columns]="columns" [value]="rowData" [(selection)]="selectedRows" [rowHover]="true" [rows]="10"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [paginator]="true" styleClass="p-datatable-striped"
            (onPage)="paginate($event)"  [scrollable]="true" [totalRecords]="100"
                scrollHeight="400px">
                <!-- [scrollable]="true" scrollHeight="400px"    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" -->
                <ng-template pTemplate="header" let-columns [autoLayout]="true">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox (click)="selectRow($event)"></p-tableHeaderCheckbox>
                        </th>
                        <th *ngFor="let col of columns" pSortableColumn="{{col.field }}">
                            <div *ngIf="col.field != 'action' && col.field != 'sort_order' && col.field != 'settingAction' && col.field != 'viewActions' && col.field != 'image'">
                                {{col.header}}
                                <p-sortIcon field="{{col.field }}" class="d-sort"></p-sortIcon>
                            </div>
                            <div *ngIf="col.field === 'sort_order'" style="text-align: center;">
                                {{col.header}}
                                <p-sortIcon field="{{col.field }}" class="d-sort"></p-sortIcon>
                            </div>
                            <div *ngIf="col.field === 'image'">
                                {{col.header}}
                            </div>
                            <!-- margin-right: 42px; -->
                            <div *ngIf="col.field === 'action'  || col.field === 'settingAction' ||  col.field === 'viewActions'" style="text-align: center;">
                                {{col.header}}
                            </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr>
                        <td style="width: 3rem">
                            <p-tableCheckbox [value]="rowData" (click)="selectRow($event)"></p-tableCheckbox>
                        </td>
                        <td *ngFor="let col of columns">
                            <div *ngIf="col.field != 'image' && col.field != 'status' && col.field != 'sort_order' && col.field != 'date_added' && col.field != 'date' && col.field != 'created_on'
                              && col.field != 'start_date' && col.field != 'end_date' && col.field != 'date_modified'">
                                {{rowData[col.field]}}
                            </div>
                            <div *ngIf="col.field === 'sort_order'" style="text-align: center;">
                                {{rowData.sort_order}}
                            </div>
                            <div *ngIf="col.field === 'image'">
                                <img [src]="rowData.imageUrl" width="60" height="50">
                            </div>
                            <div *ngIf="col.field === 'date_added' || col.field === 'date' || col.field === 'created_on'  || col.field === 'start_date' || col.field === 'end_date' ||  col.field === 'date_modified'">
                                {{rowData[col.field].split(' ')[0] }}
                              </div>
                            <div *ngIf="col.field === 'status'">
                                <span *ngIf=" rowData.status === false">Disabled</span>
                                <span *ngIf=" rowData.status === true">Enabled</span>
                                <span *ngIf=" rowData.status != true && rowData.status != false">{{rowData.status}}
                                </span>
                            </div>
                            <!-- margin-right: 30px; -->
                            <div *ngIf="col.field === 'action'" style="text-align: center;">
                                <i class="fa fa-pencil-square-o"
                                    style="font-size: 21px; color: #0071bd; cursor: pointer;"
                                    (click)="edit(rowData)"></i>&nbsp; &nbsp;
                                <i class="fa fa-trash-o" style="font-size: 21px; color: #ff7f27; cursor: pointer;"
                                    (click)="delete(rowData)"></i>
                            </div>
                            <div *ngIf="col.field === 'settingAction'" style="text-align: center;">
                                <i class="fa fa-pencil-square-o"
                                    style="font-size: 21px; color: #0071bd; cursor: pointer;"
                                    (click)="edit(rowData)"></i>&nbsp; &nbsp;
                                <!-- <i class="fa fa-trash-o" style="font-size: 21px; color: #ff7f27; cursor: pointer;"
                                    (click)="delete(rowData)"></i> -->
                            </div>
                            <div *ngIf="col.field === 'viewActions'" class="views" style="text-align: end;">
                                <i class="fa fa-eye-slash" style="font-size: 21px; color: #000000; cursor: pointer;"
                                    (click)="view(rowData)"></i>&nbsp;
                                <i class="fa fa-pencil-square-o"
                                    style="font-size: 21px; color: #0071bd; cursor: pointer;"
                                    (click)="edit(rowData)"></i>&nbsp;
                                <i class="fa fa-trash-o" style="font-size: 21px; color: #ff7f27; cursor: pointer;"
                                    (click)="delete(rowData)"></i>
                            </div>
                           
                            <!-- <div *ngIf="rowData.length == 0">
                                No values to display here
                            </div> -->
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage" let-columns>
                    <tr>
                        <td [attr.colspan]="columns.length +1" [ngStyle]="{'text-align': 'center'}">
                            No records found
                        </td>
                    </tr>
                </ng-template>
            </p-table>
         
        </div>
    </div>
</div>