import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/constants/http.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  title = 'materialUI';
  bankDetails = {
    account: '',
  };
  profileData: any;
  constructor(private httpService: HttpService) {}

  ngOnInit(): void {
    this.httpService.profileDataEvent.subscribe((res: any) => {
      console.log('Profile data is ', res);
      this.profileData = res;
    });
  }
  addBank(input) {
    // let input = (<HTMLInputElement>document.getElementById(bankInput)).value;
    if (input != '') {
      this.bankDetails['account'] = input;
    } else {
      this.bankDetails['account'] = 'No bank account yet';
    }
  }
}
