import { Injectable } from '@angular/core';

const Api = {
  categories: 'categorylistadmin',
  manuFacturer: 'Manufacturer',
  options: 'option',
  information: 'information',
  recurringProfiles: 'RecurringProfiles',
  attribute: 'Attribute',
  attributeGroup: 'AttributeGroups',
  Review: 'review',
  ProductDetails: 'products',
  productsView: 'productdetails',
  salesreturn: 'salesreturn ',
  Filters: 'filter',
  Orders: 'ordersadmin',
  productsList: 'productslist_v2',
  lengthcls: 'length_class',
  weightcls: 'weight_class',
  taxCls: 'taxclass',
  stockStatus: 'Stockstatus_operations',
  // customerGrp: 'customers/customer_groups',
  productOrder: 'ProductDetailsUI',
  customergroups: 'customers/customer_groups',
  customer: 'customers/customers',
  customer_approvals: 'customers/customer_approvals',
  customfield: 'customers/custom_field',
  Voucherstheme: 'vouchertheme',
  Vouchers: 'vouchers',
  addProduct: 'Cart',
  ordersDropDowns: 'ordersdropdowns',
  vendorList: 'vendor',
  vendorGrp: 'vendorgroups',
  vendorApproval: 'vendorapprovals',
  deliveryAgent: 'delivery_executive',
  login: 'CommonLogin',
  productFilter: 'productfilter',
  store: 'setting',
  taxrate: "taxrate",
  orderStatus: "system/localisation/order_status",
  layouts: 'layout',
  languageeditor: 'languageeditor',
  seo: 'design/seo',
  productPrice: 'productprice',
  productList: 'productlist',
  ready_for_shipment: 'ordershipping',
  vendorProducts: 'vendorproducts',
  vendorProductApprove: 'vendorproductapprove',
  stockStatuses: 'system/localisation/stock_status',
  tracking: 'affiliate',
  Cart: 'Cart',
  preview: 'Preview',
  Coupons: 'Coupons',
  Currency: 'currency',
  setting: 'SettingDetails',
  storeGrp: 'StoresAdmin',
  currency: 'currency',
  storeinsert: 'storeinsert',
  StoresAdmin: 'StoresAdmin',
  addPolicy: 'insertpolicy',
  policyDataResource: 'getqualifiers',
  policyValues: 'getqualifiervalues',
  auth: 'authorization/list_menus',
  policyModules: 'getmodules',
  usersPolicyNames: 'getpolicynames',
  getPolicy: 'policycruds',
  getUsers: 'usercruds',
  BulkUpload: 'BulkUpload',
  CommonLogout: 'CommonLogout',
  inquiry: 'StoreEnquiry',
  productTostore: "ProductToStore",
  zone: "zone",
  TenantEnquiry: "TenantEnquiry",
  tenantRegistration: 'tenant_registration',
  ordersdetails: 'ordersdetails',
  uploadDocument:'uploadDocument',
  StoreEnquiry:'StoreEnquiry',
  storeLocation: 'system/localisation/store_location',
  language: 'Language_operations',
  customerdetails: 'customerdetails',
  CouponsUi:'CouponsUi'
};


@Injectable({
  providedIn: 'root'
})
export class ApiconfigService {
  private jsonApi: any = Api;
  public baseUrl: any = 'https://demoapps.arkatiss.com/ecomm/';
  public baseUrl2: any = 'https://demoapps.arkatiss.com/security_auth/';
  public baseUrl3: any = 'https://demoapps.arkatiss.com/CMS/';
  constructor() { }

  getUrls(): any {
    return this.jsonApi;
  }
  getBaseUrl(): any{
    return this.baseUrl
  }

}
