import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { ApiconfigService } from './apiconfig.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public apiconfig: any;
  public http: any;

  constructor(apiconfig: ApiconfigService, http: HttpService) {
    this.apiconfig = apiconfig;
    this.http = http;
  }
}
