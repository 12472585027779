import { Component,Input, OnInit , OnChanges, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-view-data-table',
  templateUrl: './view-data-table.component.html',
  styleUrls: ['./view-data-table.component.scss']
})
export class ViewDataTableComponent implements OnInit {
  @Input() columns: any;
  @Input() rowData: any;
  @Input() dropList: any;
  @Output() viewEvent = new EventEmitter<string>();
  // @Output() deleteEvent = new EventEmitter<string>();
  // @Output() multiSelectEvent = new EventEmitter<string>();
  products = [];
  cols: any[];
  selectedRows:any = [];
  droplist2=['item1', 'item2']
  constructor() { }

  ngOnInit(): void {
    // (this.rowData, this.columns);
    // ('dropdown list',this.dropList);


  }
  getIndex(ds) { 
    return this.rowData.indexOf(ds); 
} 
  view(row, viewLink){
    // (row, viewLink);
    const data:any = {row: row, viewLink: viewLink }
    this.viewEvent.emit(data);
  }
}
