import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailEditorComponent } from 'angular-email-editor';
// import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-templateeditor',
  templateUrl: './templateeditor.component.html',
  styleUrls: ['./templateeditor.component.scss']
})
export class TemplateeditorComponent implements OnInit {
  constructor(public http: HttpClient, private activatedRoute: ActivatedRoute) { }

  @ViewChild(EmailEditorComponent)
  private emailEditor: EmailEditorComponent;
  templateName;
  ngOnInit(): void {
    //
    this.activatedRoute.queryParams.subscribe(params => {
      this.templateName = params.templateName;
    });
  }
  ngAfterViewInit() {
    //
    this.registerTool();
  }
  editorLoaded(e) {
    // load the design json here
    this.emailEditor.editor.loadDesign(JSON.parse(localStorage.getItem('item')).design);
  }

  exportHtml() {
    this.emailEditor.editor.exportHtml((data) => {
      //;
      const templateData = { type: 'send', name: this.templateName, data: data};
      // this.cookieService.set( 'item', JSON.stringify(data));
      localStorage.setItem('item', JSON.stringify(data));
      this.http.post('http://tcq.arkatiss.com/ecomm/StoreData', templateData).subscribe((response) => {
        //;
      })
    }
    )
  }
  registerTool() {
    //
    this.emailEditor.editor.init({ id: 'editor-1', customCSS: ['https://examples.unlayer.com/examples/custom-css/custom.css'], })
}
}