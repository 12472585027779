import { environment } from 'src/environments/environment';
import { UserRole } from '../shared/auth.roles';
// const adminRoot = environment.adminRoot;

export class IMenuItem {
  id?: string;
  icon?: string;
  label: string;
  to: string;
  newWindow?: boolean;
  subs?: IMenuItem[];
  roles?: UserRole[];
}
export class cartProductData {
  data :IMenuItem[];
}

// var data: IMenuItem[];
// const role = localStorage.getItem('Role');
// console.log(role);

