import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, ErrorHandler  } from '@angular/core';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { ViewsModule } from './views/views.module';
import { TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';

// import { Routes, RouterModule, ROUTES } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { LayoutContainersModule } from './containers/layout/layout.containers.module';
// import { MatInputModule, MatButtonModule, MatSelectModule, MatIconModule } from '@angular/material';
// import { FormsModule } from '@angular/forms';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { EditorModule } from '@tinymce/tinymce-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
// import {DatepickerModule} from 'ngx-date-picker';
// import { routes } from './app-routing';
import { PopoverModule } from 'ngx-bootstrap/popover';
// import { DatePipe } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { EmailEditorModule } from 'angular-email-editor';
import {TableModule} from 'primeng/table';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import {MatStepperModule} from '@angular/material/stepper';
import { BsDropdownModule,BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ADOrdersInterceptor } from './shared/interceptor'
import { ErrorService } from './shared/services/error-handle.service';

@NgModule({
  imports: [
    BrowserModule,
    ViewsModule,
    AppRoutingModule,
    LayoutContainersModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    NgxEditorModule,
    EditorModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    NgxDatatableModule,
    EmailEditorModule,
    TableModule,
    MatStepperModule,
    NgxDocViewerModule,
    BsDropdownModule.forRoot(),
    CommonModule
    // RouterModule.forRoot(routes)
    // MatInputModule
    // AngularFireModule.initializeApp(environment.firebase)
  ],
  declarations: [
    AppComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [DatePipe, BsDropdownConfig, ErrorService,{ provide: HTTP_INTERCEPTORS, useClass: ADOrdersInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: OrdersInterceptor, multi: true }
  ],
  // {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  bootstrap: [AppComponent],
  exports: [BsDatepickerModule, DatepickerModule]
})
export class AppModule { }
