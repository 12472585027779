import { Component, Input } from '@angular/core';
import { Router, Event, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import  { IMenuItem , cartProductData} from 'src/app/constants/menu';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/constants/common.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html'
})
export class HeadingComponent {
  @Input() title = '';
  // menuItems: cartProductData = new cartProductData() ;
  menuItems = [];
  path = '';
  IdmRole: any;
  newPaths = [{path: '/catalog/categories/add', label: 'Category'},
  {path: '/catalog/products/addProduct', label: 'Product'},
  {path: '/catalog/recurring-profiles/recurringprofiles', label: 'Subscription/Recurring'},
  {path: '/catalog/filters/addfilter', label: 'Filter'},
  {path: '/catalog/attributes/add-attribute', label: 'Attribute'},
  {path: '/catalog/attribute-groups/add-attributegroup', label: 'AttributeGroup'},
  {path: '/catalog/options/addoption', label: 'Option'},
  {path: '/catalog/manufacturers/add-manufacturer', label: 'Manufacturers'},
  {path: '/catalog/downloads/addDownload', label: 'Downloads'},
  {path: '/catalog/reviews/addReview', label: 'Review'},
  {path: '/catalog/information/addinformation', label: 'Information'},
  {path: '/sales/orders/addorder', label: 'Orders'},
  {path: '/sales/orders/addreturn', label: 'Return'},
  {path: '/sales/orders/addvoucher', label: 'Voucher'},
  {path: '/sales/orders/addvouchertheme', label: 'VoucherTheme'},
  {path: '/customers/customers/addcustomer', label: 'Customer'},
  {path: '/customers/customers/addcustomergroup', label: 'CustomerGroup'},
  {path: '/customers/customers/addcustomfield', label: 'CustomField'},
  {path: '/sales/orders/ordersview', label: 'Orders'},
  {path: '/vendors/vendors', label: 'Vendors'},
  {path: '/delivery/delivery-executive/addexecutive', label: 'Delivery Executive'},
  {path: '/vendors/addVendorProduct', label: 'Add Product'}

 ];
 
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public common: CommonService,
    public http: HttpClient) {
    this.IdmRole = localStorage.getItem('idmRole')

      this.getMenuList();
    this.router.events
    .pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      })
    ).subscribe((event) => {
     this.path = this.router.url.split('?')[0];
    });
  }
  getMenuList() {
    //;
    const success = this.onSuccessMenu.bind(this);
    const error = this.onError.bind(this);
    const body = {
      "idm_roles": [this.IdmRole],
      "appl_name": "Ecom"
    };
    this.common.http.postAuth('auth', body, success, error);
    
  }

  onSuccessMenu(data) {
    // this.menuItems.data = Array<IMenuItem>();
    // this.childrenArray.subs = Array<IMenuItem>();
    if (data.res_status == true) {
      // //(data)
      // //( 'before map',data.result.auth_objects)
      this.menuItems =  data.result.auth_objects;
      // data.result.auth_objects.map((element)=>{
      // //( 'befafter map',data.result.auth_objects)

      // // element.children.map((item)=>{
      // //   const childDataSet =  new IMenuItem();
      // //   childDataSet.to = item.link,
      // //   childDataSet.label = item.name
      // //   childDataSet.icon = 'simple-icon-check'
      // //   childDataSet.id = item.appID
      // //   childDataSet.subs = item.children
      // //   this.childrenArray.subs.push(childDataSet)
      // // //( 'chilfren menu map',this.childrenArray.subs)
      // // })

      // const dataset = new IMenuItem();
      // //( 'IN  map  ',dataset)
      //   dataset.label = element.name;
      //   // dataset.subs =  this.childrenArray.subs;
      //   dataset.subs =   element.children;
      //   dataset.icon = 'iconsminds-user';
      //   dataset.to = element.link 
      //   this.menuItems.data.push(dataset) 
      // //( 'parent menu  map  ',this.menuItems.data)

      // })
      // this.authObjects = data.result.auth_objects;
      // //('menu items response ', this.menuItems)
      const obj = data.result.roleid;
      localStorage.setItem('roleId', obj);
      sessionStorage.setItem('timeline_auth', JSON.stringify(data));
    } else {
    }
  }
  onError(error) {
  }

  getLabel(path): string {
    // if (path === environment.adminRoot) {
    //   return 'menu.home';
    // }
//;
    // step 0
    // //('menu list in getlabel', this.menuItems)
    let foundedMenuItem = this.menuItems.find(x => x.link === path);
    // //('found menu item', foundedMenuItem)
    if (!foundedMenuItem) {
      // step 1
      this.menuItems.map(menu => {
        // //('menu', menu )
        // //(' children condition', menu.children)
        if (!foundedMenuItem && menu.children) {foundedMenuItem = menu.children.find(x => x.link === path); }
      });
      if (!foundedMenuItem) {
        // step 2
        this.menuItems.map(menu => {
          if (menu.children) {
        // //('children children condition', menu.children)

            menu.children.map(sub => {
              // //('subbbbbbbb', sub)
                if (!foundedMenuItem && sub.children) {foundedMenuItem = sub.children.find(x => x.link === path); }
              });
          }
        });
        if (!foundedMenuItem) {
          // step 3
          this.menuItems.map(menu => {
            if (menu.children) {
              menu.children.map(sub => {
                if (sub.children) {
        // //('children children condition', sub.children)

                  sub.children.map(deepSub => {
                    if (!foundedMenuItem && deepSub.children) {foundedMenuItem = deepSub.children.find(x => x.link === path); }
                  });
                }
              });
            }
          });
        }
      }
    }
    for(let i=0; i<this.newPaths.length; i++){
      if (path == this.newPaths[i]['path']){
        return this.newPaths[i]['label']
      }
    }

    if (foundedMenuItem) { return foundedMenuItem.name; } else { return 'notFoundInMenu'; }
  }

}
