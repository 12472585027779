import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()

export class ADOrdersInterceptor implements HttpInterceptor {

  constructor() { 

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem('token')
// const token = 'eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJySFZQT0x1X1BZYzJGekJBNGJ6aS1BVE5hYnpXeno2MXN3dUp0VWFfYUU0In0.eyJleHAiOjE2NjA3MTIzMjMsImlhdCI6MTY2MDcxMjAyMywianRpIjoiMzI2M2YyOGMtMmNmMC00YTBjLTliMjAtOGRlYTcwNDY0YmZiIiwiaXNzIjoiaHR0cHM6Ly9ib3RwaS5hcmthdGlzcy5jb20vYXV0aC9yZWFsbXMvRUNPTSIsInN1YiI6ImVjNzM2OTU1LTlmOWYtNDI0My05ZmRlLTliZDcwMmU3NmRhZSIsInR5cCI6IkJlYXJlciIsImF6cCI6IkVjb21fYmUiLCJzZXNzaW9uX3N0YXRlIjoiMTk0MzBhOGYtNWM0MC00MGY5LWE3ZDItNWZmYjg1ZDE3ZDgyIiwiYWNyIjoiMSIsImFsbG93ZWQtb3JpZ2lucyI6WyJodHRwczovL2JvdHBpLmFya2F0aXNzLmNvbS8qIiwiKiJdLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsiZWNvbW1fYWRtaW4iXX0sInNjb3BlIjoiZW1haWwgcHJvZmlsZSIsInNpZCI6IjE5NDMwYThmLTVjNDAtNDBmOS1hN2QyLTVmZmI4NWQxN2Q4MiIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwicHJlZmVycmVkX3VzZXJuYW1lIjoianlvdGhzbmFiYXNhOEBnbWFpbC5jb20ifQ.QJ1l4GErsdBiLXs-e66MtXPy7BfKgabNZPC2KD1s80bbQPAEZS2FFZhQp26iZr-lzD_8R9azLd84MKh8VS4JXn8YP_iByjNZjxFP65EKLF6oPXUsRrNUlWWwmAfbt11GJBei7Bq5JSNwu-hWfFzrIiRPIhXyKTwsrwcIhQ8_kWWPrzRgEBKDf0Y71qCH5hSmCoWnZUeUW554h23OykuZIHoBlDIWl56jXRfiMflkYXC44_xBE9SoPQMH9wuCyn5nLyUim7tulx1xZzJ-qs_pXa4Sa5NK_ss0i0YayjzcsePpr-iowbfSWmeU5HUEbzm-5rIxEciXgKdq6yUHIDj0'
    if ((req.url.includes('filemanager') === true) || (req.url.includes('BulkUpload') === true)) {
      req = req.clone({ headers: req.headers.set('Authorization', 'Bearer').set('Content-Language', 'en-US').set('Authorization', 'Bearer ' + token) });
    } else if (req.url.includes('openid-connect/token') === true) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/x-www-form-urlencoded') });
    } else {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + token) });
    }
    return next.handle(req).pipe(catchError(this.handleError));

    // headers = new HttpHeaders({
      // + JSON.parse(localStorage.getItem('mpManagerToken')
    //     'Content-Type': 'multipart/form-data',
    // });
    // .set('Content-Type', 'multipart/form-data')
    // }
    // req = req.clone({ headers: req.headers.append('Content-Type', 'application/x-www-form-urlencoded')});

  }
  handleError(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error);
  }
}


