import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';


@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnInit, OnChanges {
  @Input() columns: any;
  @Input() rowData: any;
  @Output() paginationEvent = new EventEmitter<string>();
  @Output() editEvent = new EventEmitter<string>();
  @Output() viewEvent = new EventEmitter<string>();
  @Output() deleteEvent = new EventEmitter<string>();
  @Output() multiSelectEvent = new EventEmitter<string>();
  products = [];
  cols: any[];

  ColumnMode = ColumnMode;
  rowDataDetails = [];
  displayedColumnsDetails = [];
  temp = [];
  itemsPerPage = 10;
  itemOptionsPerPage = [5, 10, 20];
  selectedRows: any = [];
  constructor() {
    // //(this.rowData, this.columns);

  }

  ngOnInit(): void {

    // //(this.rowData, this.columns);
  }
  edit(row): any {
    // //(row);
    this.editEvent.emit(row);
  }
  view(row): any {
    // //(row);
    this.viewEvent.emit(row);
  }
  delete(row): any {
    // //(row);
    this.deleteEvent.emit(row);
  }
  ngOnChanges(changes: SimpleChanges): void {
    // this.rowDataDetails = this.rowData.currentValue;
    // //(this.rowDataDetails);
    // this.displayedColumnsDetails = this.displayedColumns.currentValue;
    // //(this.rowData, this.displayedColumns, changes);
  }
  paginate(event){
    console.log('pagination select',event)
    this.paginationEvent.emit(event);
  }
  selectRow(e): any {
    // //(e);
    // //(this.selectedRows);
    this.multiSelectEvent.emit(this.selectedRows);
    // this.selectedRows = [];
  }
}
