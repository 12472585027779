import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { SidebarService, ISidebar } from '../sidebar/sidebar.service';
import { Router } from '@angular/router';
import { LangService, Language } from 'src/app/shared/lang.service';
// import { AuthService } from 'src/app/shared/auth.service';
import { environment } from 'src/environments/environment';
import { getThemeColor, setThemeColor } from 'src/app/utils/util';
import { CommonService } from 'src/app/constants/common.service';
import { HttpService } from 'src/app/constants/http.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
})
export class TopnavComponent implements OnInit, OnDestroy {
  profileData: any;
  params;
  success;
  error;
  headers;
  buyUrl = environment.buyUrl;
  adminRoot = environment.adminRoot;
  sidebar: ISidebar;
  subscription: Subscription;
  displayName: any;
  languages: Language[];
  currentLanguage: string;
  isSingleLang;
  isFullScreen = false;
  isDarkModeActive = false;
  searchKey = '';
  role: any;

  constructor(
    private sidebarService: SidebarService,
    private common: CommonService,
    // private authService: AuthService,
    private router: Router,
    private langService: LangService,
    private httpService: HttpService
  ) {
    this.languages = this.langService.supportedLanguages;
    this.currentLanguage = this.langService.languageShorthand;
    this.isSingleLang = this.langService.isSingleLang;
    this.isDarkModeActive = getThemeColor().indexOf('dark') > -1 ? true : false;
    this.displayName = localStorage.getItem('userName');
    this.role = localStorage.getItem('Role');
    this.sessionId = sessionStorage.getItem('sessionId');
    this.commonUserId = sessionStorage.getItem('userId');
  }
  sessionId;
  commonUserId;

  onDarkModeChange(event): void {
    let color = getThemeColor();
    if (color.indexOf('dark') > -1) {
      color = color.replace('dark', 'light');
    } else if (color.indexOf('light') > -1) {
      color = color.replace('light', 'dark');
    }
    setThemeColor(color);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }

  fullScreenClick(): void {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  }

  @HostListener('document:fullscreenchange', ['$event'])
  handleFullscreen(event): void {
    if (document.fullscreenElement) {
      this.isFullScreen = true;
    } else {
      this.isFullScreen = false;
    }
  }

  onLanguageChange(lang): void {
    this.langService.language = lang.code;
    this.currentLanguage = this.langService.languageShorthand;
  }

  async ngOnInit(): Promise<void> {
    // if (await this.authService.getUser()) {
    //   this.displayName = await this.authService.getUser().then((user) => {
    //     return user.displayName;
    //   });
    // }

    this.subscription = this.sidebarService.getSidebar().subscribe(
      (res) => {
        this.sidebar = res;
      },
      (err) => {
        console.error(`An error occurred: ${err.message}`);
      }
    );

    // this.sidebarService.setContainerClassnames(3, 'menu-sub-hidden', true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  menuButtonClick = (
    e: { stopPropagation: () => void },
    menuClickCount: number,
    containerClassnames: string
  ) => {
    // ('menu clicked');
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);
    // (menuClickCount);
    this.sidebarService.setContainerClassnames(
      ++menuClickCount,
      containerClassnames,
      this.sidebar.selectedMenuHasSubItems
    );
  };

  mobileMenuButtonClick = (
    event: { stopPropagation: () => void },
    containerClassnames: string
  ) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  };

  onSignOut(): void {
    const body = {
      user_type: this.role,
      session_id: this.sessionId,
      user_name: this.displayName,
    };
    this.params = { headers: this.headers };
    this.success = this.onSuccessLogOut.bind(this);
    this.error = this.onErrorLogOut.bind(this);
    this.common.http.post(
      'CommonLogout',
      body,
      this.success,
      this.error,
      this.params
    );
  }

  onSuccessLogOut(res) {
    if (res.status == 200) {
      localStorage.removeItem('userName');

      localStorage.removeItem('userMail');
      localStorage.removeItem('Role');
      localStorage.removeItem('idmRole');
      sessionStorage.removeItem('sessionId');
      localStorage.removeItem('userId');
      sessionStorage.removeItem('userId');
      sessionStorage.removeItem('storeId');
      localStorage.removeItem('tenantId');
      localStorage.removeItem('customerList');
      localStorage.removeItem('tempZoneList');
      localStorage.removeItem('productList');
      localStorage.removeItem('frequency');
      localStorage.removeItem('tempCurrencyList');
      localStorage.removeItem('categoryData');
      localStorage.removeItem('country');
      localStorage.removeItem('productList');
      sessionStorage.removeItem('token');
    }
    this.router.navigate(['/']);

    // window.location.href = 'https://demoapps.arkatiss.com/app-config/#/login'
  }
  onErrorLogOut(res) {}

  searchKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.search();
    } else if (event.key === 'Escape') {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) {
        input.classList.remove('mobile-view');
      }
      this.searchKey = '';
    }
  }

  searchAreaClick(event): void {
    event.stopPropagation();
  }
  searchClick(event): void {
    if (window.innerWidth < environment.menuHiddenBreakpoint) {
      let elem = event.target;
      if (!event.target.classList.contains('search')) {
        if (event.target.parentElement.classList.contains('search')) {
          elem = event.target.parentElement;
        } else if (
          event.target.parentElement.parentElement.classList.contains('search')
        ) {
          elem = event.target.parentElement.parentElement;
        }
      }

      if (elem.classList.contains('mobile-view')) {
        this.search();
        elem.classList.remove('mobile-view');
      } else {
        elem.classList.add('mobile-view');
      }
    } else {
      this.search();
    }
    event.stopPropagation();
  }

  search(): void {
    if (this.searchKey && this.searchKey.length > 1) {
      this.router.navigate([this.adminRoot + '/pages/miscellaneous/search'], {
        queryParams: { key: this.searchKey.toLowerCase().trim() },
      });
      this.searchKey = '';
    }
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(event): void {
    const input = document.querySelector('.mobile-view');
    if (input && input.classList) {
      input.classList.remove('mobile-view');
    }
    this.searchKey = '';
  }

  account() {
    const body = {
      tenant_id: sessionStorage.getItem('userId'),
    };
    this.httpService.accountProfile(body).subscribe((res) => {
      this.profileData = res.data[0];
      this.httpService.profileData(this.profileData);
      console.log('AccountProfile:', res);
    });
  }
}
