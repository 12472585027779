export const blogData = [
  {
    title: 'Distinctively Exploit Optimal Alignments with Mentality Networks',
    description: 'Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration. Collaboratively administrate via plug-and-play networks.',
    thumb: '/assets/img/details/small-5.jpg',
    badge: 'NEW',
    type: 'image'
  },
  {
    title: 'Progressively Maintain Extensive Infomediaries',
    description: 'Credibly reintermediate backend ideas for cross-platform models. Continually reintermediate integrated processes through technically sound intellectual capital.',
    thumb: '/assets/img/details/small-2.jpg',
    badge: 'UPDATE',
    type: 'video'
  },
  {
    title: 'Assertively Iterate Resource Maximizing',
    description: 'Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration.',
    thumb: '/assets/img/details/small-3.jpg',
    type: 'image'
  },
  {
    title: 'Podcasting Operational Change Management Inside of Workflows',
    description: 'Quickly deploy strategic networks with compelling e-business. Credibly pontificate highly efficient manufactured products and enabled data.',
    thumb: '/assets/img/details/small-7.jpg',
    badge: 'TRENDING',
    type: 'image'
  },
  {
    title: 'Objectively Innovate Empowered Manufactured Products',
    description: 'Completely synergize resource taxing relationships via premier niche markets.',
    thumb: '/assets/img/details/small-1.jpg',
    type: 'image'
  },
  {
    title: 'Completely Pursue Scalable Customer Service',
    description: 'Credibly reintermediate backend ideas for cross-platform models. Continually reintermediate integrated processes through technically sound intellectual capital.',
    thumb: '/assets/img/details/small-6.jpg',
    type: 'image'
  },
  {
    title: 'Dynamically Procrastinate B2C Users After Installed Base Benefits',
    description: 'Dramatically maintain clicks-and-mortar solutions without functional solutions. Completely synergize resource taxing relationships via premier niche markets.',
    thumb: '/assets/img/details/small-7.jpg',
    type: 'image'
  },
  {
    title: 'Progressively Maintain Extensive Infomediaries',
    description: 'Dramatically maintain clicks-and-mortar solutions without functional solutions.',
    thumb: '/assets/img/details/small-8.jpg',
    type: 'image'
  }
];

export const blogCategories = [
  {
    title: 'Using the Template',
    link: '#',
    icon: 'iconsminds-director'
  },
  {
    title: 'Security',
    link: '#',
    icon: 'iconsminds-security-settings'
  },
  {
    title: 'Account Settings',
    link: '#',
    icon: 'iconsminds-male'
  },
  {
    title: 'Policies and Docs',
    link: '#',
    icon: 'iconsminds-newspaper'
  }
];
